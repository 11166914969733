<template>
   <section class="updateUser">
        <div class="row">
            <div class="col-12 mb-3">
                <h3 class="text-muted">Créer un groupe</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-form @submit="onSubmit">
                            <validationObserver>
                                <div class=row>
                                    <div class="col-12">
                                        <span style="float: right;">* champs obligatoires</span>                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <validationProvider name="Nom du groupe" rules="required|max:255" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Nom du groupe *" label-for="tenant_name">
                                            <b-form-input id="tenant_name" v-model="form.tenant_name" type="text" required></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.tenant_name">{{ form_errors.tenant_name[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>

                                    <!-- Annuler pour le moment A voir plus tard ! -->
                                    <!-- <validationProvider name="Logo du groupe" rules="required" v-slot="{ errors }" class="mb-3 col-md-6">
                                      <b-form-group label="Logo du groupe" label-for="tenant_logo">
                                        <b-form-file id="tenant_logo" v-model="form.tenant_logo"
                                          accept=".jpg, .jpeg, .png" plain>
                                        </b-form-file>
                                        <div class="mt-3">Fichier sélectionné: {{ form.tenant_logo ? form.tenant_logo.name : '' }}</div>
                                        <span style="color:red">{{ errors[0] }}</span>
                                        <span style="color:red" v-if="form_errors.tenant_logo">{{ form_errors.tenant_logo[0] }}</span>
                                      </b-form-group>
                                    </validationProvider> -->
                                </div>

                                <div class="row">
                                    <div class="col-sm-10">
                                        <b-button type="submit" variant="primary">Créer</b-button>
                                    </div>
                                </div>
                            </validationObserver>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
export default {
    props: {

    },

    data() {
        return {
            form: {
                tenant_name: '',
                // tenant_logo: null,
            },
            form_errors: {},
        }
    },

    methods: {
        onSubmit(event) {
            event.preventDefault();
            axios.post('/tenants', {
                'tenant_name': this.form.tenant_name,
                // 'tenant_logo': this.form.tenant_logo
            })
            .then(response => {
                alert(`Le groupe ${this.form.tenant_name} a bien été créé !`);
                window.location.href = '/home';
            })
            .catch(error => {
              this.form_errors = error.response.data.errors
            })
        },
    }
}
</script>

<style>
</style>
