/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';
import { BootstrapVue, IconsPlugin, TablePlugin, FormPlugin } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
// Pour VueJS 3.x
// import { defineRule } from 'vee-validate'
import { confirmed, min, max, numeric, email, required, required_if, length, digits } from 'vee-validate/dist/rules';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// ================== Pour VueJS 3.x
// import UsersCreate from './components/users/Create.vue';
// import UsersEdit from './components/users/Edit.vue';
// import UsersShow from './components/users/Show.vue';


// const app = createApp({})

// app
//   .use(BootstrapVue)
//   .use(IconsPlugin)
//   .use(TablePlugin)
//   .use(FormPlugin)

// app
//   .component('ValidationProvider', ValidationProvider)
//   .component('ValidationObserver', ValidationObserver)
//   .component('UsersCreate', UsersCreate)
//   .component('UsersEdit', UsersEdit)
//   .component('UsersShow', UsersShow)

// app.mount('#app')

// Fin VueJS 3.x ==================


window.Vue = require('vue').default;

Vue.use(BootstrapVue);
Vue.use(TablePlugin);
Vue.use(IconsPlugin);
Vue.use(FormPlugin);

// Vee validate
extend('confirmed', {
  ...confirmed,
  message: "Le champ '{_field_}' et le mot de passe ne correspondent pas"
});
extend('min', {
  validate(value, { length }){
    return value.length >= length
  },
  params: ['length'],
  message: "Le champ '{_field_}' doit contenir au moins {length} caractères"
});
extend('max', {
  validate(value, { length }){
    return value.length <= length
  },
  params: ['length'],
  message: "Le champ '{_field_}' doit contenir au maximum {length} caractères"
});
extend('email', {
  ...email,
  message: "Le champ '{_field_}' doit contenir une adresse email valide"
});
extend('required', {
  ...required,
  message: "Le champ '{_field_}' est requis"
});
extend('required_if', {
  ...required_if,
  message: "Le champ '{_field_}' est requis"
});
extend('length', {
  ...length,
  message: "Le champ '{_field_}' doit contenir {length} caractères"
});
extend('digits', {
  ...digits,
  message: "Le champ '{_field_}' doit être numérique et contenir {length} chiffres"
});
// Validation with uppercase, digits and dot
extend('capsNum', {
  validate(value){
    return /^[A-Z0-9.]+$/.test(value);
  },
  message: "Le champ '{_field_}' doit contenir des chiffres et des majuscules"
});
// Validation with uppercase, lowercase, digits, space and dashes (-)
extend('alphaNum', {
  validate(value){
    return /^[a-zA-Z0-9 -]+$/.test(value);
  },
  message: "Le format du champ '{_field_}' est invalide"
});



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

// Components for users
Vue.component('users-create', require('./components/users/Create.vue').default);
Vue.component('users-edit', require('./components/users/Edit.vue').default);
Vue.component('users-show', require('./components/users/Show.vue').default);
//Vue.component('users-index', require('./components/users/Index.vue').default);
//Vue.component('users-index-tab', require('./components/users/Tab.vue').default);

// Components for devices
Vue.component('devices-index', require('./components/devices/Index.vue').default);
Vue.component('devices-create', require('./components/devices/Create.vue').default);
Vue.component('devices-show', require('./components/devices/Show.vue').default);
Vue.component('devices-overview', require('./components/devices/Overview.vue').default);
Vue.component('devices-line-chart-level', require('./components/devices/LineChartLevel.vue').default);
// A remplacer par 'devices-edit
Vue.component('devices-setting', require('./components/devices/Setting.vue').default);

// Components for type of devices in devices-index
Vue.component('devices-index-relays', require('./components/devices/index/Relays.vue').default);
Vue.component('devices-index-others', require('./components/devices/index/Others.vue').default);

// Components for devices blocs
Vue.component('devices-blocs-chart', require('./components/devices/blocs/Chart.vue').default);
Vue.component('devices-blocs-general-information', require('./components/devices/blocs/GeneralInformation.vue').default);
Vue.component('devices-blocs-last-datas', require('./components/devices/blocs/LastDatas.vue').default);

// Component for datas
Vue.component('datas-index', require('./components/datas/Index.vue').default);
Vue.component('datas-map', require('./components/datas/Map.vue').default);
Vue.component('datas-show', require('./components/datas/Show.vue').default);
Vue.component('datas-table', require('./components/datas/Table.vue').default);

// Component for dashboard
Vue.component('dashboard', require('./components/dashboard/Dashboard.vue').default);
Vue.component('dashboard-doughnut-chart-level', require('./components/dashboard/DoughnutChartLevel.vue').default);
Vue.component('dashboard-tab', require('./components/dashboard/Tab.vue').default);

// Components for dashboard blocs
Vue.component('dashboard-blocs-list', require('./components/dashboard/blocs/List.vue').default);
Vue.component('dashboard-blocs-alarms', require('./components/dashboard/blocs/Alarms.vue').default);
Vue.component('dashboard-blocs-levels', require('./components/dashboard/blocs/Levels.vue').default);
Vue.component('dashboard-blocs-states', require('./components/dashboard/blocs/States.vue').default);
Vue.component('dashboard-blocs-compost', require('./components/dashboard/blocs/Compost.vue').default);

// Component for tenants
Vue.component('tenants-create', require('./components/tenants/Create.vue').default);

// Component for applications
Vue.component('applications-create', require('./components/applications/Create.vue').default);

Vue.component('scroll-top', require('./components/ScrollTop.vue').default);

// Components for export / en pause car ça bug 
Vue.component('export-csv', require('./components/export/Csv.vue').default);
Vue.component('export-pdf', require('./components/export/Pdf.vue').default);

// Components for vee-validate, for form validation
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Component for pagination tables
Vue.component('pagination', require('laravel-vue-pagination'));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

