<template>
    <div :class="hasRelayDevices ? 'col-md-3' : 'd-none'">
        <div class="custom-block">
            <h5>État des alarmes</h5>
            <ul>
                <li v-for="device in relayDevices" :key="device.id">
                    {{  device.device_name }} - 
                    <button
                    :class="['btn', 'rounded-circle', 'p-2', {
                        'btn-success': device.alarm_on === 'true',
                        'btn-danger': device.alarm_on !== 'true'
                    }]"
                    :title="device.alarm_on === 'true' ? 'Activée' : 'Désactivée'"
                    disabled
                    ></button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        application: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasRelayDevices () {
            return this.application.devices.some(device => device.device_type === 'relay')
        },
        relayDevices () {
            return this.application.devices.filter(device => device.device_type === 'relay')
        },
    }
}
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>