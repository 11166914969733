<template>
    <div class="mr-3 ml-3">
        <select v-model="selectedOption">
            <option value="hour">Heure</option>
            <option value="day">Jour</option>
            <option value="week">Semaine</option>
            <option value="month">Mois</option>
            <option value="year">An</option>
        </select>

        <LineChartGenerator
        id="myLineChart"
        ref="myLineChart"
        :options="chartOptions"
        :data="chartData"
        :chart-id="`line-${deviceId}`"
        :width="width"
        :height="height"
        />
    </div>
</template>
  
<script>
    import { Line as LineChartGenerator } from 'vue-chartjs'
    import annotationPlugin from 'chartjs-plugin-annotation'
    import zoomPlugin from 'chartjs-plugin-zoom'
    import moment from 'moment'
    import 'moment/locale/fr'
    import 'chartjs-adapter-moment'

    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        CategoryScale,
        PointElement,
        TimeScale,
    } from 'chart.js'
  
    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        LinearScale,
        CategoryScale,
        PointElement,
        TimeScale,
        annotationPlugin,
        zoomPlugin
        )
  
    export default {
        name: 'LineLevel',
    
        components: {
            LineChartGenerator
        },
    
        props: {
            width: {
                type: Number,
                default: 800
            },
            height: {
                type: Number,
                default: 600
            },
            tenant: {
                type: Object,
                required: true
            },
            applicationId: {
                type: Number,
                required: true
            },
            deviceId: {
                type: Number,
                required: true
            },
            deviceName: {
                type: String,
                required: true
            }
        },
    
        data() {
            return {
                datas: [],
                selectedOption: 'hour',
                start: null,
                end: null,
                allData: [],
                intervalId: null,
                limitZoomXMin: null
            }
        },

        mounted(){
                this.insertDatas()

                this.intervalId = setInterval(() => {
                    this.insertDatas()
                }, 30000)
        },

        beforeDestroy() {
            clearInterval(this.intervalId)
        },

        computed: {
            chartData() {
                return {
                    datasets: [
                        {
                            label: this.deviceName,
                            backgroundColor: '#f87979',
                            data: this.addRate(),
                        }
                    ]
                }
            },

            chartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Graphique du taux de remplissage'
                        },
                        zoom: {
                            zoom: {
                                wheel: {
                                    enabled: true,
                                    modifierKey: 'ctrl'
                                },
                                pinch: {
                                    enabled: true,
                                },
                                mode: 'xy',
                            },
                            pan: {
                                enabled: true,
                            },
                            limits: {
                                x: {
                                    min: this.limitZoomXMin,
                                    max: this.limitZoomXMax
                                },

                                y: {
                                    min: 0,
                                    max: 100,
                                    minRange: 10
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                            type: 'time',
                            min: this.start,
                            max: this.end,
                            ticks: {
                                autoSkip: true,
                                autoSkipPadding: 50,
                                maxRotation: 0,
                            },
                            time: {
                                // unit: this.selectedOption,
                                displayFormats: {
                                    day: 'DD MMM YYYY',
                                    hour: 'DD MMM HH:mm',
                                    minute: 'HH:mm',
                                    second: 'HH:mm:ss'
                                },
                                tooltipFormat: 'DD MMM YYYY - HH:mm:ss',
                            },
                            adapter: {
                                date: moment
                            }
                        },
                        y: {
                            display: true,
                            type: 'linear',
                            title: {
                                display: true,
                                text: 'Taux de remplissage (en %)'
                            },
                            suggestedMin: 0,
                            suggestedMax: 100
                        }
                    },

                }
            }
        },
    
        methods: {
            insertDatas() {
                this.datas = []

                axios.get("/sanctum/csrf-cookie")
                    .then(response => {
                        axios.get(`/api/tenants/${this.tenant.id}/applications/${this.applicationId}/devices/${this.deviceId}/datas`)
                            .then(response => {
                                response.data.data_sensors.forEach(data_sensor => {
                                    this.datas.push({x: new Date(data_sensor.date).valueOf(), y: data_sensor.rate})
                                })
                                
                                if(this.datas.length > 0) {
                                    const lastData = this.datas[this.datas.length - 1]
                                    this.start = moment(lastData.x).add(-1, this.selectedOption + 's')
                                    this.end = lastData.x
                                    this.limitZoomXMin = this.datas[0].x
                                    this.limitZoomXMax = moment(lastData.x).add(1, this.selectedOption + 's')
                                }
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            addRate() {
                if(this.datas !== undefined && this.datas.length > 0){
                    return this.datas.map(data => ({
                        x: data.x,
                        y: data.y
                    }));
                }else{
                    return []
                }

                // let y = 100
                // for(let x = this.start; x <= this.end; x += 100000) {
                //     y += 5 - Math.random() * 10
                //     this.allData.push({x, y})
                // }

                // return this.fetchData(this.start, this.end)
                // return this.allData
            },

            fetchData(x1, x2) {
                const step = Math.max(1, Math.round((x2 - x1) / 100000))
                const data = []
                let i = 0
                console.log(i < this.allData.length, this.allData[i].x < x1)
                while(i < this.allData.length && this.allData[i].x < x1) {
                    i++
                    console.log(this.allData.length, this.allData[i].x, i)
                }
                console.log(i < this.allData.length, this.allData[i].x <= x2)
                while(i < this.allData.length && this.allData[i].x <= x2) {
                    data.push(this.allData[i])
                    i += step
                }

                console.log(data)
                return data
            },

            // A revoir plus tard
            // refreshChart() {
            //     this.$nextTick(() => {
            //         const chart1 = this.$refs.myLineChart.chart
            //         console.log('1 ' + chart1)
                    
            //         if(this.$refs.myLineChart){
            //             const chart2 = this.$refs.myLineChart.chart
            //             console.log('2 ' + chart2)

            //             if(chart2) {
            //                 console.log('2 ' + chart2)
            //                 chart2.resetZoom()
            //             }
            //         }
            //     })
            // }
        }
    }

</script>