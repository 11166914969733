<template>
   <section class="updateUser">
        <div class="row">
            <div class="col-12 mb-3">
                <h3 class="text-muted">Créer un utilisateur</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-form @submit="onSubmit">
                            <validationObserver>
                                <div class=row>
                                    <div class="col-12">
                                        <span style="float: right;">* champs obligatoires</span>                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <validationProvider name="Nom" rules="required|max:255" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Nom complet *" label-for="user_name">
                                            <b-form-input id="user_name" v-model="form.user_name" type="text" required></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.user_name">{{ form_errors.user_name[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>

                                    <validationProvider name="Email" rules="email|required|max:255" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Email *" label-for="email">
                                            <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.email">{{ form_errors.email[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>
                                </div>
                                <div class="row">
                                    <validationProvider name="Mot de passe" rules="min:8|required|confirmed:password_confirmation" vid="password" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Mot de passe *" label-for="password">
                                            <b-form-input id="password" v-model="form.password" type="password" required></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.password">{{ form_errors.password[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>
                                    <validationProvider name="Confirmation du mot de passe" rules="min:8|required_if:password" vid="password_confirmation" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Confirmation du mot de passe *" label-for="password_confirmation">
                                            <b-form-input id="password_confirmation" v-model="form.password_confirmation" type="password" required></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.password_confirmation">{{ form_errors.password_confirmation[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>
                                </div>
                                <div class="row">
                                  <validationProvider name="Numéro de téléphone" rules="required|digits:10" v-slot="{ errors }" class="mb-3 col-md-6">
                                      <b-form-group label="Numéro de téléphone *" label-for="phone_number">
                                          <b-form-input id="phone_number" v-model="form.phone_number" type="tel" required></b-form-input>
                                          <span style="color:red">{{ errors[0] }}</span>
                                          <span style="color:red" v-if="form_errors.phone_number">{{ form_errors.phone_number[0] }}</span>
                                      </b-form-group>
                                  </validationProvider>
                                </div>

                                <div class="row mb-3">
                                    <template v-if="isAdmin">
                                        <div class="col-sm-10">
                                            <b-form-group label="Role *" v-slot="{ ariaDescribedBy }">
                                                <b-form-radio v-model="form.role" :aria-describedby="ariaDescribedBy" class="role" value="user">Utilisateur</b-form-radio>
                                                <b-form-radio @change="changeRole()" v-model="form.role" :aria-describedby="ariaDescribedBy" class="role" value="admin">Administrateur</b-form-radio>
                                            </b-form-group>
                                        </div>
                                        <div class="col-sm-10">
                                            <b-form-group v-if="form.role === 'user'" label="Nom de l'application *" label-for="application_id">
                                                <b-form-select id="application_id" v-model="form.application_id" required>
                                                    <b-form-select-option :value="null" disabled>Veuillez sélecionner une application</b-form-select-option>
                                                    <template v-for="application in applicationsTenant.applications">
                                                        <b-form-select-option :key="application.id" v-model="application.id">
                                                            {{application.application_name}}
                                                        </b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <span style="color:red" v-if="form_errors.application_id">{{ form_errors.application_id[0] }}</span>
                                            </b-form-group>
                                        </div>
                                    </template>
                                </div>

                                <div class="row">
                                    <div class="col-sm-10">
                                        <b-button type="submit" variant="primary">Créer</b-button>
                                    </div>
                                </div>
                            </validationObserver>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
export default {
    props: {
        tenant: { type: Object, required: true },
        applicationsTenant: {type: Object, required: true},
        isAdmin: {type: Number, required: true},
    },

    data() {
        return {
            form: {
                user_name: '',
                email: '',
                password:  '',
                password_confirmation: '',
                phone_number: '',
                role: '',
                application_id: null,
            },
            form_errors: {},
        }
    },

    methods: {
        onSubmit(event) {
            event.preventDefault();
            axios.post(`/tenants/${this.tenant.id}/users`, {
                'user_name': this.form.user_name,
                'email': this.form.email,
                'password': this.form.password,
                'password_confirmation': this.form.password_confirmation,
                'phone_number': this.form.phone_number,
                'role': this.form.role,
                'application_id': this.form.application_id,

            })
            .then(response => {
                alert(`L'utilisateur ${this.form.user_name} a bien été créé !`);
                window.location.href = `/tenants/${this.tenant.id}/users`;
            })
            .catch(error => {
               this.form_errors = error.response.data.errors
            })
        },

        changeRole() {
            if (this.form.role === 'admin') {
                this.form.application_id = null
            }
        }
    },
}
</script>

<style>
</style>
