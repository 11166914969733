<template>
    <div class="custom-block">
        <h4>Capteurs ({{ totalRows }})</h4>
        <b-alert show dismissible fade v-if="intervalMsg !== null" variant="success">
            {{ intervalMsg }}
        </b-alert>
        <b-alert show dismissible fade v-if="intervalErrorMsg !== null" variant="danger">
            {{ intervalErrorMsg }}
        </b-alert>

        <b-form-group label="Nombre de lignes" label-for="nb-per-page" label-cols-lg="3">
            <b-form-select
                class="nb-per-page col-lg-3"
                v-model="perPage"
                :options="perPageOptions"
            >
            </b-form-select>
        </b-form-group>

        <b-table 
            hover 
            responsive 
            bordered 
            :per-page="perPage" 
            :currentPage="currentPage" 
            :fields="allFields()" 
            :items="devicesProvider" 
            :busy.sync="isBusy"
        >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Chargement...</strong>
                </div>
            </template>

            <template #cell(device_name) = "data">
                <b-link :href="`/tenants/${tenantId}/devices/${data.item.id}`">{{ data.item.device_name }}</b-link>
            </template>
            <template #cell(device_on) = "data">
                <b-form-checkbox 
                    v-if="data.item.device_type === 'door' || data.item.device_type === 'motion'" 
                    switch 
                    v-model="data.item.device_on"
                    @input="switchDeviceOnOff(data.item.id, data.item.device_on, data.item.device_name)">
                    <span v-if="data.item.device_on == 'true' || data.item.device_on == true" style="color:green">Activé</span>
                    <span v-else style="color:red">Désactivé</span>
                </b-form-checkbox>
            </template>
            <template #cell(device_interval) = "data">
                <b-form-select v-if="data.item.device_type === 'level' || data.item.device_type === 'compostmetre'" 
                    id="selectInterval" 
                    v-model="data.item.device_interval" 
                    :disabled="countdownBool"
                    @change="changeInterval(data.item.id, data.item.device_interval, data.item.device_name)"
                >
                    <option :value="null" disabled>Veuillez sélectionner un intervalle de réception</option>
                    <option v-for="option in interval_options" :value="option.value" :disabled="option.value == data.item.device_interval">
                        {{ option.text }}
                    </option>
                </b-form-select>
                <p v-if="countdownBool && data.item.device_type === 'level'" style="color: red">
                    Veuillez attendre {{ countdown }} avant de changer l'intervalle
                </p>
            </template>
        </b-table>

        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
    </div>
</template>

<script>
    export default {
        props: {
            application: {
                required: true,
                type: Object
            },

            tenantId: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                intervalMsg: null,
                intervalErrorMsg: null,
                countdownBool: false,
                countdown: null,
                interval_options: [
                    { value: 1, text: '1h' },
                    { value: 2, text: '2h' },
                    { value: 4, text: '4h' },
                    { value: 6, text: '6h' },
                    { value: 12, text: '12h' },
                    { value: 24, text: '24h' }
                ],
                fields: [
                    { key: 'device_name', label: 'Capteur', visible: true },
                    { key: 'device_interval', label: 'Intervalle de réception des données', visibleLevel: true },
                    { key: 'device_on', label: 'Capteur statut', visibleDevice: true },
                ],
                isBusy: false,
                totalRows: 0,
                currentPage: 1,
                perPage: 5,
                perPageOptions: [5, 10, 25]
            }
        },

        mounted() {
            if(localStorage.countdownDate && localStorage.countdownDate > 0) {
                this.countdownInterval()
            }
        },

        methods: {
            devicesProvider(ctx){
                const promise = axios.get(`/api/tenants/${this.tenantId}/applications/${this.application.id}/devices`, {
                    params: {
                        per_page: ctx.perPage,
                        page: ctx.currentPage
                    }
                })

                return promise.then((response) => {
                    const items = response.data.data
                    this.totalRows = response.data.total

                    return items || []
                })
                .catch(() => {
                    return []
                })
            },

            allFields(){
                let visibleFields = []

                this.application.devices.forEach((device) => {
                    if(device.device_type === 'level' || device.device_type === 'compostmetre'){
                        this.fields.forEach((field) => {
                            if(field.visible || field.visibleLevel){
                                visibleFields.push({ ...field })
                            }
                        })
                    }else{
                        this.fields.forEach((field) => {
                            if(field.visible || field.visibleDevice){
                                visibleFields.push({ ...field })
                            }
                        })
                    }
                })

                return visibleFields
            },

            switchDeviceOnOff(device_id, device_on, device_name){
                axios.post(`/tenants/${this.tenantId}/applications/${this.application.id}/devices/${device_id}/switch-device-on-off`, {
                    'device_on': device_on
                })
                .then(() => {
                    alert(`Le capteur ${device_name} est maintenant ${device_on ? 'activé': 'désactivé'}`);
                })
                .catch((error) => {
                    // console.log(error);
                });
            },

            changeInterval(device_id, interval, device_name){
                if(this.countdownBool){
                    this.intervalErrorMsg = "Vous ne pouvez pas modifier l'intervalle"
                }else{
                    axios.post(`/tenants/${this.tenantId}/applications/${this.application.id}/devices/${device_id}/change-interval`, {
                        'interval': interval
                    })
                    .then((response) => {
                        this.intervalMsg = 
                            `Vous avez changé l'intervalle de réception des données du capteur ${device_name}. L'intervalle est maintenant de ${interval}h`

                        this.countdownInterval(interval)
                    })
                    .catch((error) => {
                        this.intervalErrorMsg = error
                    });
                }
            },

            countdownInterval(interval){
                if(interval){
                    // Mettre (interval * 60 * 60 * 1000) pour avoir en heure
                    var countdownDate = new Date().getTime() + (interval * 60 * 1000)
                    localStorage.countdownDate = countdownDate
                }

                this.countdownBool = true

                const intervalId = setInterval(() => {
                    var now = new Date().getTime()
                    var distance = localStorage.countdownDate - now

                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000)

                    this.countdown = `${hours}h ${minutes}min ${seconds}s`

                    if(distance <= 0) {
                        clearInterval(intervalId)
                        this.countdownBool = false
                        this.countdown = null
                        localStorage.countdownDate = 0
                        this.intervalErrorMsg = null
                    }
                }, 1000)
            }
        }
    }
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>
