<template>
    <div>

        <div class="row">
            <div class="col-12">
                <div :class="currentTab !== 0 ? 'card rounded-card-1' : 'card rounded-card-0'">
                    <div class="card-body">
                        <h5>Page paramètres</h5>

                        <!-- <div v-if="device.device_type === 'level'" class="select_interval">
                        <b-dropdown id="dropdown" :text="interval === '' ? 'Sélectionnez un intervalle' : 'Vous avez choisi : ' + interval">
                            <b-dropdown-item id="interval" v-for="(option, index) in options" :key="option.value"
                            @click="select(index)">{{ option.text }}</b-dropdown-item>
                        </b-dropdown>

                        <b-button variant="primary" @click="chooseInterval">Envoyer</b-button>

                        <b-alert show dismissible variant="success" v-if="intervalChanged">
                            Vous avez changé l'intervalle de réception des données du capteur {{ chooseIntervalSensor }}.
                            <br>
                            L'intervalle est maintenant de {{ interval }}
                        </b-alert>
                        <b-alert show dismissible variant="danger" v-if="error">
                            Une erreur est survenue. Veuillez réessayer
                        </b-alert>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
    export default {
        props: {
            tenant: Object,
            device: Object,
            currentTab: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                options: [
                    { value: '1h', text: '1h' },
                    { value: '2h', text: '2h' },
                    { value: '4h', text: '4h' },
                    { value: '6h', text: '6h' },
                    { value: '12h', text: '12h' },
                    { value: '24h', text: '24h' }
                ],
                interval: '',
                intervalChanged: false,
                chooseIntervalSensor: '',
                error: false
            }
        },

        methods: {
            select(index){
                this.interval = this.options[index].text

                if(this.intervalChanged === true){
                    this.intervalChanged = false
                }
            },

            chooseInterval(event) {
                event.preventDefault();

                axios.post('/change_interval', {
                    'interval': this.interval,
                    'chooseIntervalSensor': this.chooseIntervalSensor,
                    // 'chooseIntervalApplication': this.chooseIntervalApplication,
                })
                .then(() => {
                    console.log("Success. . . . ")
                    alert("Vous receverez maintenant des données toutes les "+ this.interval +" !");
                    this.intervalChanged = true
                })
                .catch(error => {
                    console.log("ERRRRR..... ", error.response.data.message)
                    this.error = true
                });
            },
        }
    }
</script>

<style>
    .rounded-card-0 {
        border-radius: 0 20px 20px 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }

    .rounded-card-1 {
        border-radius: 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }
</style>