<template>
    <div class="col-md-3">
        <div class="custom-block">
            <h4>Informations générales</h4>

            <div>
                <b-form-group label="Type du capteur" label-for="device_type" class="mb-3">
                    <b-form-input 
                        id="device_type" 
                        class="mb-3 cursor-not-allowed" 
                        disabled 
                        readonly 
                        v-model="device.device_type"
                    >
                    </b-form-input>
                </b-form-group>

                <b-form-group v-if="device.device_type === 'level'" 
                    label="Type de conteneur" 
                    label-for="container_type" 
                    class="mb-3"
                >
                    <b-form-input 
                        id="container_type" 
                        class="cursor-not-allowed" 
                        disabled 
                        readonly 
                        v-model="device.container_type"
                    >
                    </b-form-input>
                </b-form-group>

                <b-form-group v-if="device.device_type === 'level'" 
                    label="Caractéristique du conteneur" 
                    label-for="container_characteristic" 
                    class="mb-3"
                >
                    <b-form-input 
                        id="container_characteristic" 
                        class="mb-3 cursor-not-allowed" 
                        disabled 
                        readonly 
                        v-model="device.container_characteristic"
                    >
                    </b-form-input>
                </b-form-group>

                <b-form-group label="Date de création du capteur" label-for="created" class="mb-3">
                    <b-form-input
                        id="created"
                        class="cursor-not-allowed"
                        disabled readonly
                        v-model="formattedDate"
                    >
                    </b-form-input>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            device: {
                required: true,
                type: Object
            }
        },

        data() {
            return {
                formattedDate: ''
            }
        },

        mounted() {
            this.formatDate()
        },

        methods: {
            formatDate() {
                let dateObject = new Date(this.device.created_at)
                let hours = dateObject.getHours()
                let minutes = dateObject.getMinutes()
                let day = dateObject.getDate()
                let month = dateObject.getMonth() + 1
                let year = dateObject.getFullYear()

                hours = hours < 10 ? "0" + hours : hours
                minutes = minutes < 10 ? "0" + minutes : minutes
                day = day < 10 ? "0" + day : day
                month = month < 10 ? "0" + month : month

                this.formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`
            },
        }
    }
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }

    .cursor-not-allowed {
        cursor: not-allowed;
    }
</style>