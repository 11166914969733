<template>
    <div class="row">
        <div class="col-12">
            <div class="card rounded-card">
                <div class="card-body">
                    <b-alert v-if="success !== null" variant="success" dismissible>{{ success }}</b-alert>
                    <b-alert v-else-if="error !== null" variant="danger" dismissible>{{ error }}</b-alert>

                    <button v-if="dragMode" @click="saveOrder">Enregistrer l'ordre</button>

                    <b-dropdown variant="transparent" text="Options" toggle-class="text-decoration-none" no-caret class="float-right">
                        <template #button-content>
                            <i class="bi bi-three-dots-vertical"></i>
                        </template>
                        <b-dropdown-item @click="toggleDragMode">
                            <span v-if="dragMode" class="ml-2">&#10003;</span>
                            Activer le mode organiser
                        </b-dropdown-item>
                    </b-dropdown>

                    <draggable v-bind="dragOptions" :list="deviceOverviewBlocsOrder" @update="onBlocksChange" class="row">
                        <component v-for="order in deviceOverviewBlocsOrder" 
                            :key="`order-${order.name}`" 
                            :is="`devices-blocs-${order.name}`" 
                            :applicationId="application.id"
                            :tenant="tenant"
                            :device="device"
                            :currentTab="currentTab"
                            @send-tab="sendNewTab"
                        >
                        </component>
                    </draggable>

                    <scroll-top></scroll-top>

                </div>
            </div>
        </div>
    </div>    
</template>

<script>
    export default {
        props: {
            tenant: {
                required: true,
                type: Object
            },
            device: {
                required: true,
                type: Object
            },
            application: {
                required: true,
                type: Object
            },
            currentTab: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                deviceOverviewBlocsOrder: [],
                defaultDeviceOverviewBlocsOrder: [
                    {
                        name: 'chart',
                        order: 0
                    },
                    {
                        name: 'general-information',
                        order: 1
                    },
                    {
                        name: 'last-datas',
                        order: 2
                    }
                ],
                dragMode: false,
                success: null,
                error: null
            }
        },

        computed: {
            dragOptions() {
                return {
                    animation: 150,
                    disabled: !this.dragMode
                }
            }
        },

        mounted() {
            this.initBlocsOrders()
        },

        methods: {
            initBlocsOrders() {
                if (this.application.users[0].pivot.deviceOverviewBlocsOrder !== null) {
                    this.deviceOverviewBlocsOrder = this.application.users[0].pivot.deviceOverviewBlocsOrder
                        .slice()
                        .sort((a, b) => a.order - b.order)
                } else {
                    this.deviceOverviewBlocsOrder = this.defaultDeviceOverviewBlocsOrder.slice()
                }
            },

            toggleDragMode() {
                this.dragMode = !this.dragMode
            },

            saveOrder() {
                // A voir comment je peux gérer ça par rapport au device
                axios.post(`/api/tenants/${this.tenant.id}/applications/${this.application.id}/device/${this.device.id}/deviceOverviewBlocsOrder`, {
                    deviceOverviewBlocsOrder: this.deviceOverviewBlocsOrder
                })
                .then(response => {
                    this.dragMode = !this.dragMode
                    this.success = response.data.message
                })
                .catch(error => {
                    this.error = "Une erreur s'est produite. Veuillez recharger la page et réessayer d'enregistrer l'ordre"
                })
            },

            onBlocksChange() {
                this.deviceOverviewBlocsOrder = [...this.deviceOverviewBlocsOrder].map((order, index) => {
                    return {...order, order: index}
                })
            },

            sendNewTab(newTab) {
                this.$emit('change-tab', newTab)
            }
        }
    }
</script>

<style>
    .rounded-card {
        border-radius: 0 20px 20px 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }
</style>