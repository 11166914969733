<template>
   <section class="updateUser">
        <div class="row">
            <div class="col-12 mb-3">
                <h3 class="text-muted">Modifier votre profil</h3>
            </div>
            <div class="col-12">
                <a :href="`/tenants/${tenant.id}/users`">Retour</a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-form @submit="onSubmit">
                            <validationObserver>
                                <div class=row>
                                    <div class="col-12">
                                        <span style="float: right;">* champs obligatoires</span>                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <validationProvider name="Nom" rules="required|max:255" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Nom *" label-for="user_name">
                                            <b-form-input id="user_name" v-model="form.user_name" type="text" required></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.user_name">{{ form_errors.user_name[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>
                                    <validationProvider name="Email" rules="email|required|max:255" v-slot="{ errors }" class="mb-3 col-md-6">
                                    <b-form-group label="Email *" label-for="email">
                                        <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
                                        <span style="color:red">{{ errors[0] }}</span>
                                        <span style="color:red" v-if="form_errors.email">{{ form_errors.email[0] }}</span>
                                    </b-form-group>
                                </validationProvider>
                                </div>
                                <div class="row">
                                    <validationProvider name="Nouveau mot de passe" rules="min:8|confirmed:new_password_confirmation" vid="new_password" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Nouveau mot de passe" label-for="new_password">
                                            <b-form-input id="new_password" v-model="form.new_password" type="password"></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.new_password">{{ form_errors.new_password[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>
                                    <validationProvider name="Confirmation du nouveau mot de passe" rules="min:8|required_if:new_password" vid="new_password_confirmation" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label="Confirmation du nouveau mot de passe" label-for="new_password_confirmation">
                                            <b-form-input id="new_password_confirmation" v-model="form.new_password_confirmation" type="password"></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.new_password_confirmation">{{ form_errors.new_password_confirmation[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>
                                </div>
                                <div class="row">
                                  <validationProvider name="Numéro de téléphone" rules="required|digits:10" v-slot="{ errors }" class="mb-3 col-md-6">
                                      <b-form-group label="Numéro de téléphone *" label-for="phone_number">
                                          <b-form-input id="phone_number" v-model="form.phone_number" type="tel" required></b-form-input>
                                          <span style="color:red">{{ errors[0] }}</span>
                                          <span style="color:red" v-if="form_errors.phone_number">{{ form_errors.phone_number[0] }}</span>
                                      </b-form-group>
                                  </validationProvider>
                                </div>

                                <div class="row mb-3">
                                    <template v-for="tenantUser in tenantsUserAuth">
                                            <template v-if="tenantUser.role === 'admin' && tenantUser.tenant_id === form.currentTenantId">
                                                <div class="col-sm-10">
                                                    <b-form-group label="Role" v-slot="{ ariaDescribedBy }">
                                                        <b-form-radio v-model="form.role" :aria-describedby="ariaDescribedBy" class="role" value="user">Utilisateur</b-form-radio>
                                                        <b-form-radio v-model="form.role" :aria-describedby="ariaDescribedBy" class="role" value="admin">Administrateur</b-form-radio>
                                                        <span style="color:red" v-if="form_errors.role">{{ form_errors.role[0] }}</span>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-sm-10">
                                                    <b-form-group label="Nom du groupe" label-for="tenant_id">
                                                        <b-form-select id="tenant_id" v-model="form.tenant" required>
                                                            <template v-for="tenant in tenants">
                                                                <b-form-select-option v-if="tenant.id === tenantUser.tenant_id || isFullTenant" :key="tenant.id" v-model="tenant.id">
                                                                    {{tenant.tenant_name}}
                                                                </b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <span style="color:red" v-if="form_errors.tenant">{{ form_errors.tenant[0] }}</span>
                                                    </b-form-group>
                                                </div>
                                            </template>
                                     </template>
                                </div>

                                <b-form-group v-if="form.tenant != tenantUser.tenant_id">
                                    <label>Souhaitez-vous intégrer l'utilisateur {{ user.user_name }} dans un nouveau groupe ?</label>
                                    <b-button :variant="form.newTenant === false ? 'primary' : 'outline-primary'" name="newTenant" v-model="form.newTenant" @click="onClickNewTenant">Ajouter</b-button>
                                    <b-button :variant="form.updateTenant === false ? 'primary' : 'outline-primary'" name="updateTenant" v-model="form.updateTenant" @click="onClickUpdateTenant">Modifier</b-button>
                                    <b-button variant="primary" @click="onClickCancel">Annuler</b-button>
                                </b-form-group>

                                <template v-for="allTenantUser in allTenantsUser">
                                    <p v-if="form.tenant === allTenantUser.tenant_id && form.tenant != tenantUser.tenant_id" style="color: red">
                                        {{ form.warning }}
                                    </p>
                                </template>

                                <div class="row">
                                    <div class="col-sm-10">
                                        <b-button type="submit" variant="primary">Modifier</b-button>
                                    </div>
                                </div>
                            </validationObserver>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
export default {
    props: {
        user: { type: Object, required: true },
        tenantUser:{ type: Object, required: true },
        tenants:{ type: Array, required: true },
        tenantsUserAuth:{ type: Array, required: true },
        isFullTenant: { type: Number, required: true },
        allTenantsUser: { type: Array, required: true },
        tenant: { type: Object, required: true },
    },

    data() {
        return {
            form: {
                user_name: this.user.user_name,
                email: this.user.email,
                new_password: '',
                new_password_confirmation: '',
                phone_number: this.user.phone_number,
                role: this.tenantUser.role,
                tenant: this.tenantUser.tenant_id,
                currentTenantId: this.tenantUser.tenant_id,
                newTenant: false,
                updateTenant: false,
                warning: '',
            },
            form_errors: {},
        }
    },

    methods: {
        onSubmit(event) {
            event.preventDefault();
            if (this.form.newTenant == true) {
                axios.put("tenants/" + this.tenant.id + '/users/' + this.user.id, {
                    'user_name': this.form.user_name,
                    'email': this.form.email,
                    'new_password': this.form.new_password,
                    'new_password_confirmation': this.form.new_password_confirmation,
                    'phone_number': this.form.phone_number,
                    'role': this.form.role,
                    'tenant_id': this.form.tenant,
                    'currentTenantId': this.form.currentTenantId,
                    'newTenant': this.form.newTenant,
                }).then(response => {
                    if (response.data != '') {
                        this.form.warning = response.data
                    } else {
                        alert("L'utilisateur '" + this.form.user_name + "' a bien été ajouté !'");
                        window.location.href = "/tenants/" + this.tenant.id + "/users/" + this.user.id;
                    }
                })
                .catch(error => {
                  this.form_errors = error.response.data.errors
                })
            } else if(this.form.updateTenant == true || this.form.tenant == this.form.currentTenantId) {
                axios.put("/tenants/" + this.tenant.id + "/users/" + this.user.id, {
                    'user_name': this.form.user_name,
                    'email': this.form.email,
                    'new_password': this.form.new_password,
                    'new_password_confirmation': this.form.new_password_confirmation,
                    'phone_number': this.form.phone_number,
                    'role': this.form.role,
                    'tenant_id': this.form.tenant,
                    'currentTenantId': this.form.currentTenantId,
                    'updateTenant': this.form.updateTenant,
                })
                .then(response => {
                    if (response.data != '') {
                        this.form.warning = response.data
                    } else {
                        alert("L'utilisateur '" + this.form.user_name + "' a bien été mis à jour !");
                        window.location.href = "/tenants/" + this.tenant.id + "/users/" + this.user.id;
                    }
                })
                .catch(error => {
                    this.form_errors = error.response.data.errors
                })
            }
        },

        onClickNewTenant(event) {
            event.preventDefault();
            this.form.newTenant = true;
            this.form.updateTenant = false
        },

        onClickUpdateTenant(event) {
            event.preventDefault();
            this.form.newTenant = false;
            this.form.updateTenant = true
        },

         onClickCancel(event) {
            event.preventDefault();
            this.form.tenant = this.tenantUser.tenant_id;
            this.form.newTenant = false;
            this.form.updateTenant = false;
        }
    },


}
</script>

<style>
</style>
