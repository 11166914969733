<template>
    <div>
        <div>
        	<h4 v-if="tenant.applications.length === 0 && isAdmin === 1">
            	Vous n'avez pas d'application. <br>
            	Veuillez nous contacter à l'adresse mail: <a href="mailto:contact@octosystem.eu">contact@octosystem.eu</a>
				<!-- Veuillez créer une application dans l'onglet "Applications" -> "Ajouter une application" -->
			</h4>
        </div>

		<b-tabs class="col-12" active-nav-item-class="custom-tabs" @input="updateCurrentTab">
			<b-tab v-for="(application, index) in tenant.applications" :key="index" :title="application.application_name" lazy>
				<datas-table 
					:key="`application-${application.id}-datas-table`"
					:tenant="tenant"
					:application="application"
					:is-admin="isAdmin"
					:is-full-tenant="isFullTenant"
					:currentTab="currentTab"
				/>
			</b-tab>
		</b-tabs>

		<scroll-top></scroll-top>

    </div>
</template>

<script>
	export default {
		name: 'devices-index',
		props: {
			tenant: Object,
			isAdmin: Number,
      		isFullTenant: Number
		},
		data() {
			return {
				currentTab : 0
			}
		},

		methods: {
			updateCurrentTab(newTab) {
				this.currentTab = newTab
			},
		}
	}
</script>

<style>
	.custom-tabs .nav-tabs .nav-items {
        border-radius: 20px 20px 0 0 !important;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

</style>