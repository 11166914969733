<template>
   <section class="profileUser">
      <div class="row">
        <div class="col-12 mb-3">
          <h3 class="text-muted">Profil</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="pt-3 pl-3">
              <div class="media">
                <b-avatar size="lg" class="mr-2"></b-avatar>
                <div class="media-body">
                  <p class="mt-0 mb-0" v-if="role.role === 'admin'">administrateur</p>
                  <p class="mt-0 mb-0" v-else>utilisateur</p>
                  <h5>{{ user.user_name }}</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="mb-4">
                <ul class="list-group">
                  <li class="list-group-item pl-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-fill mr-1" viewBox="0 0 16 16">
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                    </svg>
                    <span>{{ user.email }}</span>
                  </li>
                  <li class="list-group-item pl-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill mr-1" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                    <span v-if="user.phone_number !== null">{{ user.phone_number }}</span>
                    <span v-else>Aucun numéro renseigné</span>
                  </li>
                </ul>
              </div>

              <div>
                <b-button class="w-auto" variant="primary" :href="`/tenants/${tenant.id}/users/${user.id}/edit`">Modifier</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
   </section>
</template>

<script>
export default {
    props: {
        tenant: { type: Object, required: true },
        user: { type: Object, required: true },
        role: { type: Object, required: true },
    },

    data() {
        return {

        }
    }
}
</script>

<style>
</style>
