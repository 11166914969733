<template>
    <div class="row justify-content-around">
        <template v-if="loading">
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Chargement...</strong>
            </div>
        </template>
        <template v-else>
            <p v-for="device in datasLevel.devices" :key="device.id">
                <template v-if="device.last_data !== null">
                    <DoughnutLevel
                    :tenantId="tenantId"
                    :deviceId="device.id"
                    :deviceName="device.device_name"
                    :dataRate="device.last_data.rate"
                    :color="fillColor(device.last_data.rate)"
                    />
                </template>
                <template v-else>
                    <b-link class="text-center" :href="`/tenants/${tenantId}/devices/${device.id}/datas`">
                        <p style="font-weight: bold; font-size: 1rem" class="mb-1">
                            {{ device.device_name }}
                        </p>
                    </b-link>
                    <p class="pl-4 pr-4 text-center">Vous n'avez pas encore reçu de données</p>
                </template>
            </p>
        </template>
    </div>
</template>

<script>
    import DoughnutLevel from './DoughnutLevel.vue'

    export default {
        name: 'DoughnutChartLevel',

        components: {
            DoughnutLevel
        },

        props: {
            tenantId: {
                type: Number,
                required: true
            },
            applicationId: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                datasLevel: [],
                loading: true,
            }
        },

        mounted() {
            setInterval(() => {
                this.insertDatas()
            }, 10000);
        },

        methods: {
            insertDatas() {
                axios.get("/sanctum/csrf-cookie")
                    .then(response => {
                        axios.get(`/api/tenants/${this.tenantId}/applications/${this.applicationId}/datas/levels`)
                        .then(response => {
                            this.datasLevel = response.data
                        })
                    })
                    .catch(error => {
                        // console.log(error);
                })

                this.loading = false
            },

            fillColor(level){
                if(level >= 78 && level <= 100){
                    return "#ff0017"
                }else if(level > 45 && level < 78){
                    return "#ffaf00"
                }else if(level >= 0 && level <= 45){
                    return "#19d895"
                }else{
                // A revoir la couleur quand il y a une erreur
                return "#6087FF"
                }
            }
        }
    }
</script>
