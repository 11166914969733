<template>
    <div class="mr-3 ml-3">
        <b-link style="text-align: center" :href="`/tenants/${tenantId}/devices/${deviceId}`">
            <p style="font-weight: bold; font-size: 1rem" class="mb-1">
                {{ deviceName }}
            </p>
        </b-link>

        <Doughnut
        :options="chartOptions"
        :data="chartData"
        :chart-id="`doughnut-${deviceId}`"
        :width="width"
        :height="height"
        />
    </div>
</template>

<script>
    import { Doughnut } from 'vue-chartjs'

    import {
        Chart as ChartJS,
        Title,
        ArcElement,
        Legend
    } from 'chart.js'

    ChartJS.register(Title, ArcElement, Legend)

    export default {
        name: 'DoughnutLevel',

        components: {
            Doughnut
        },

        props: {
            width: {
                type: Number,
                default: 150
            },
            height: {
                type: Number,
                default: 200
            },
            tenantId: {
                type: Number,
                required: true
            },
            deviceId: {
                type: Number,
                required: true
            },
            deviceName: {
                type: String,
                required: true
            },
            dataRate: {
                type: Number,
                required: true
            },
            color: {
                type: String,
                required: true
            }
        },

        data() {
            return {
        
            }
        },

        computed: {
            chartData() {
                return {
                    datasets: [
                    {
                        backgroundColor: [this.color, '#eaeaea'],
                        data: [this.dataRate, 100 - this.dataRate]
                    }
                    ]
                }
            },

            chartOptions() {
                return {
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Taux de remplissage (en %)',
                            position: 'bottom',
                        },
                        elements: {
                            center: {
                                text: `${this.dataRate}%`
                            }
                        }
                    }
                }
            }
        },

        mounted() {
            this.textCenter();
        },

        methods: {
            textCenter() {
                ChartJS.register({
                    id: 'p1',
                    beforeDraw: function(chart) {
                        var width = chart.width;
                        var height = chart.height;
                        var ctx = chart.ctx;

                        ctx.restore();
                        var fontSize = (height / 114).toFixed(2)
                        ctx.font = fontSize + "em sans-serif"
                        ctx.textBaseline = "middle"
                        ctx.textAlign = "center"

                        var text = chart.config.options.plugins.elements.center.text
                        var textX = ((chart.chartArea.left + chart.chartArea.right) / 2)
                        var textY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)

                        ctx.fillText(text, textX, textY);
                        ctx.save()
                    }
                });

                ChartJS.unregister(this.chartData)
            }
        }
    }
</script>
