<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h3 class="text-muted">Mes capteurs</h3>
        </div>

        <b-tabs class="col-12" active-nav-item-class="custom-tabs" @input="updateCurrentTab">
            <b-tab v-for="(application, index) in tenant.applications" :key="index" :title="application.application_name" lazy>
                <div class="row">
                    <div class="col-12">
                        <div :class="currentTab !== 0 ? 'card rounded-card-1' : 'card rounded-card-0'">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <devices-index-relays v-if="hasRelayDevices(application)"
                                            :application="application"
                                            :tenant-id="tenant.id"
                                        />
                                        
                                        <!-- A modifier ou supprimer -->
                                        <!-- <div v-else>
                                            <div class="row d-flex justify-content-center">
                                                <div class="alert alert-danger p-4" role="alert">
                                                    Vous n'avez pas de relais. 
                                                    Vous pouvez en ajouter <a :href="`/tenants/${tenant.id}/devices/create`">ici</a>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="col-md-12">
                                        <devices-index-others v-if="hasOtherDevices(application)"
                                            :application="application"
                                            :tenant-id="tenant.id"
                                        />
                                        <div v-else>
                                            <div class="row d-flex justify-content-center">
                                                <div class="alert alert-danger p-4" role="alert">
                                                    Vous n'avez pas de capteurs. 
                                                    Vous pouvez en ajouter <a :href="`/tenants/${tenant.id}/devices/create`">ici</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

	                                <scroll-top></scroll-top>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    export default {
        props: {
            tenant: {
                required: true,
                type: Object
            },

            application: {
                required: true,
                type: Array
            },

            isAdmin: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                currentTab: 0
            }
        },

        methods: {
            updateCurrentTab(newTab) {
                this.currentTab = newTab
            },

            hasRelayDevices(application) {
                return application.devices.some(device => device.device_type === 'relay')
            },

            hasOtherDevices(application) {
                return application.devices.some(device => ['motion', 'door', 'level', 'compostmetre'].includes(device.device_type))
            }
        }
    }
</script>

<style>
    .custom-tabs .nav-tabs .nav-items {
        border-radius: 20px 20px 0 0 !important;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .rounded-card-0 {
        border-radius: 0 20px 20px 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }

    .rounded-card-1 {
        border-radius: 20px;
        box-shadow: 10px 10px 10px 1px rgba(0, 0, 0, 0.1);
    }
</style>