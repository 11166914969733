<template>
    <div>
        <LineLevel
        :tenant="tenant"
        :applicationId="applicationId"
        :deviceId="device.id"
        :deviceName="device.device_name"
        />
    </div>
</template>

<script>
    import LineLevel from './LineLevel.vue'

    export default {
        name: 'LineChartLevel',

        components: {
            LineLevel
        },

        props: {
            tenant: {
                type: Object,
                required: true
            },
            applicationId: {
                type: Number,
                required: true
            },
            device: {
                type: Object,
                required: true
            }
        },

        data() {
            return {

            }
        }
    }
</script>
