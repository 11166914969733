<template>
    <div :class="hasLevelDevices ? 'col-md-6' : 'd-none'">
        <div class="custom-block">
            <h5>Niveau des capteurs</h5>
            <dashboard-doughnut-chart-level 
            :tenant-id="application.tenant_id"
            :application-id="application.id"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        application: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasLevelDevices () {
            return this.application.devices.some(device => device.device_type === 'level')
        },
    }
}
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>