<template>
	<section class="updateUser">
		<div class="row">
			<div class="col-12 mb-3">
				<h3 class="text-muted">Ajouter un capteur</h3>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<b-form @submit="onSubmit">
							<ValidationObserver>
								<div class=row>
									<div class="col-12">
										<span style="float: right;">* champs obligatoires</span>                                        
									</div>
								</div>
								<div class="row">
									<ValidationProvider name="Nom du groupe" rules="required" v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Nom du groupe *" label-for="tenant_id">
											<b-form-select id="tenant_id" @change="changeTenantId()" v-model="form.tenant_id" required>
												<b-form-select-option :value="null" disabled>Veuillez sélectionner un groupe</b-form-select-option>
												<b-form-select-option v-if="isFullTenant === 0" :key="tenant.id" v-model="tenant.id">
													{{ tenant.tenant_name}}
												</b-form-select-option>
												<template v-else v-for="allTenant in applicationsTenant">
													<b-form-select-option :key="allTenant.id" v-model="allTenant.id">
														{{ allTenant.tenant_name }}
													</b-form-select-option>
												</template>
											</b-form-select>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.tenant_id">{{ form_errors.tenant_id[0] }}</span>
										</b-form-group>
									</ValidationProvider>

									<ValidationProvider name="Nom de l'application" rules="required" v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Nom de l'application *" label-for="app_id">
											<b-form-select id="app_id" v-model="form.app_id" required>
												<b-form-select-option :value="null" disabled>Veuillez sélectionner une application</b-form-select-option>
												<template v-if="isFullTenant === 1">
													<template v-for="applicationTenant in applicationsTenant">
														<template v-for="application in applicationTenant.applications">
															<b-form-select-option v-if="applicationTenant.id === form.tenant_id" :key="application.id" 
															v-model="application.id">{{ application.application_name }}</b-form-select-option>
														</template>
													</template>
												</template>
												<template v-else>
													<template v-for="application in applicationsTenant.applications">
														<b-form-select-option :key="application.id" v-model="application.id">
															{{ application.application_name}}
														</b-form-select-option>
													</template>
												</template>
											</b-form-select>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.app_id">{{ form_errors.app_id[0] }}</span>
										</b-form-group>
									</ValidationProvider>
								</div>
								<div class="row">
									<ValidationProvider name="Nom du capteur" rules="required|min:3|max:255" v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Nom du capteur *" label-for="device_name">
											<b-form-input id="device_name mb-3" v-model="form.device_name" required></b-form-input>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.device_name">{{ form_errors.device_name[0] }}</span>
										</b-form-group>
									</ValidationProvider>

									<ValidationProvider name="Type de capteur" rules="required" v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Type de capteur *" label-for="device_type">
											<b-form-select id="device_type" @change="changeDeviceType()" v-model="form.device_type" required>
												<b-form-select-option :value="null" disabled>Veuillez sélectionner un type de capteur</b-form-select-option>
												<template v-for="deviceType in deviceTypes">
													<b-form-select-option v-model="deviceType.value">{{ deviceType.name }}</b-form-select-option>                                
												</template>
												<!-- <b-form-select-option value="motion">Mouvement</b-form-select-option>
												<b-form-select-option value="level">Niveau</b-form-select-option>
												<b-form-select-option value="relay">Relais</b-form-select-option> -->
											</b-form-select>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.device_type">{{ form_errors.device_type[0] }}</span>
										</b-form-group>
									</ValidationProvider>
								</div>
								<div class="row">
									<ValidationProvider name="Type de contenant" v-if="form.device_type === 'level'" rules="required_if:form.device_type,level" 
									v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Type de contenant *" label-for="container_type">
											<b-form-select id="container_type" v-model="form.container_type" required>
												<b-form-select-option :value="null" disabled>Veuillez sélectionner un type de contenant</b-form-select-option>
												<template v-for="containerType in containerTypes">
													<b-form-select-option v-model="containerType.value">{{ containerType.name }}</b-form-select-option>
												</template>
												<!-- <b-form-select-option value="poubelle-enterrée">Poubelle enterrée</b-form-select-option>
												<b-form-select-option value="corbeille">Corbeille</b-form-select-option> -->
											</b-form-select>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.container_type">{{ form_errors.container_type[0] }}</span>
										</b-form-group>
									</ValidationProvider>

									<ValidationProvider name="Contenant" v-if="form.device_type === 'level'" rules="required_if:form.device_type,level" 
									v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Contenant *" label-for="container_characteristic">
											<b-form-select id="container_characteristic" v-model="form.container_characteristic" required>
												<b-form-select-option :value="null" disabled>Veuillez sélectionner le contenant</b-form-select-option>
												<template v-for="containerCharacteristic in containerCharacteristics">
													<b-form-select-option v-model="containerCharacteristic.value">
														{{ containerCharacteristic.name }}
													</b-form-select-option>
												</template>
												<!-- <b-form-select-option value="emballages">Emballages</b-form-select-option>
												<b-form-select-option value="ordures-ménagères">Ordures ménagères</b-form-select-option>
												<b-form-select-option value="déchets">Déchets</b-form-select-option> -->
											</b-form-select>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.container_characteristic">
												{{ form_errors.container_characteristic[0] }}
											</span>
										</b-form-group>
									</ValidationProvider>
								</div>
								<div class="row">
									<ValidationProvider name="EUI de l'application" rules="required|digits:16" v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="EUI de l'application *" label-for="app_eui">
											<b-form-input class="mb-3" id="app_eui" v-model="form.app_eui" required></b-form-input>
											<b-button variant="primary" @click="generator($event, 'app_eui')">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
												class="bi bi-arrow-repeat" viewBox="0 0 16 16">
													<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
													<path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
												</svg>
												<span class="align-bottom">Générer</span>
											</b-button>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.app_eui">{{ form_errors.app_eui[0] }}</span>
										</b-form-group>
									</ValidationProvider>

									<ValidationProvider name="EUI du capteur" rules="required|digits:16" v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="EUI du capteur *" label-for="dev_eui">
											<b-form-input class="mb-3" id="dev_eui" v-model="form.dev_eui" required></b-form-input>
											<b-button variant="primary" @click="generator($event, 'dev_eui')">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
												class="bi bi-arrow-repeat" viewBox="0 0 16 16">
													<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
													<path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
												</svg>
												<span class="align-bottom">Générer</span>
											</b-button>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.dev_eui">{{ form_errors.dev_eui[0] }}</span>
										</b-form-group>
									</ValidationProvider>
								</div>
								<div class="row">
									<ValidationProvider name="Clé de l'application" rules="required|length:32" v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Clé de l'application *" label-for="app_key">
											<b-form-input class="mb-3" id="app_key" v-model="form.app_key" required></b-form-input>
											<b-button variant="primary" @click="generator($event, 'app_key')">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
												class="bi bi-arrow-repeat" viewBox="0 0 16 16">
													<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
													<path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
												</svg>
												<span class="align-bottom">Générer</span>
											</b-button>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color:red" v-if="form_errors.app_key">{{ form_errors.app_key[0] }}</span>
										</b-form-group>
									</ValidationProvider>

									<div v-if="form.device_type === 'level'" class="mb-3 col-md-6">
										<span>Veuillez cliquer sur la carte pour choisir l'emplacement du capteur *</span>
										<div id="map" style="width: 100%; height: 200px;"></div>
										<span v-if="lat && lng != ''">lat: {{ lat }} / lng: {{ lng }}</span>
										<span v-else style="color:red">La latitude et la longitude ne sont pas encore ajoutées</span><br>
										<span style="color:red" v-if="form_errors.lat">{{ form_errors.lat[0] }}</span><br>
										<span style="color:red" v-if="form_errors.lng">{{ form_errors.lng[0] }}</span>
									</div>
								</div>

								<div v-if="form.device_type === 'level'" class="row">
									<ValidationProvider name="Emplacement du capteur" rules="required_if:form.device_type,level" 
									v-slot="{ errors }" class="mb-3 col-md-6">
										<b-form-group label="Emplacement du capteur *" label-for="location">
											<b-form-input class="mb-3" id="location" v-model="form.location" required></b-form-input>
											<span style="color:red">{{ errors[0] }}</span>
											<span style="color: red" v-if="form_errors.location">{{ form_errors.location[0] }}</span>
										</b-form-group>
									</ValidationProvider>
								</div>

								<div class="form-group row mb-0">
									<div class="col-md-6">
										<b-button type="submit" variant="primary">Créer</b-button>
									</div>
								</div>
							</ValidationObserver>
						</b-form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			tenant: {
				type: Object,
				required: true
			},
			applicationsTenant: [Object, Array],
			isFullTenant: {
				type: Number,
				required: true
			},
			deviceTypes: {
				type: Object,
				required: true
			},
			containerTypes: {
				type: Object,
				required: true
			},
			containerCharacteristics: {
				type: Object,
				required: true
			}
		},

		data() {
			return {
				form: {
					tenant_id: null,
					app_id: null,
					app_eui: null,
					device_name: null,
					device_type: null,
					container_type: null,
					container_characteristic: null,
					dev_eui: null,
					app_key: null,
					location: null,
				},

				form_errors: {},

				icon: L.icon({
					iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
					shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowSize: [41, 41]
				}),

                map: null,
                marker: null,
                zoom: 7,
                center: [48.8566, 2.3522],
                url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVsaWVuZm9pc29uIiwiYSI6ImNrNWZlYmg0bDA3OW0za282azZ1amNpMnMifQ.CPy2dQrh_3rd35jxxT5sTQ',
                attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                lat: null,
                lng: null
			}
		},

		methods: {
			initMap() {
                this.map = L.map('map', {
                    zoom: this.zoom,
                    center: this.center,

                    fullscreenControl: true,
						fullscreenControlOptions: {
							position: 'topleft'
					}
                });

                L.tileLayer(this.url, {
					attribution: this.attribution
				}).addTo(this.map);

                this.map.on('click', this.onMapClick);
            },

            onMapClick(event) {
                const latLng = event.latlng;
                this.lat = latLng.lat;
                this.lng = latLng.lng;

                if(this.marker) {
                    this.map.removeLayer(this.marker)
                }

                this.marker = L.marker(latLng, { icon: this.icon }).addTo(this.map);

                if(this.markerError != null) {
                    this.markerError = null
                }
            },

			onSubmit(event) {
				event.preventDefault();
				axios.post(`/tenants/${this.tenant.id}/devices`, {
					'app_id': this.form.app_id,
					'app_eui': this.form.app_eui,
					'device_name': this.form.device_name,
					'device_type': this.form.device_type,
					'container_type': this.form.container_type,
					'container_characteristic': this.form.container_characteristic,
					'dev_eui': this.form.dev_eui,
					'app_key': this.form.app_key,
					'tenant_id': this.form.tenant_id,
					'location': this.form.location,
					'lat': this.lat,
					'lng': this.lng,
				})
				.then(response => {
					alert(`Le capteur ${this.form.device_name} a bien été créé !`);
					window.location.href = `/tenants/${this.tenant.id}/devices`;
				})
				.catch(error => {
					this.form_errors = error.response.data.errors
				})
			},

			generator(event, message) {
				event.preventDefault();
				var result = '';
				
				if(message === 'app_key') {
					var characters = '0123456789ABCDEF';
					var charactersLength = characters.length;

					for(var i = 0; i < 32; i++) {
						result += characters.charAt(Math.floor(Math.random() * charactersLength));
					}

					this.form.app_key = result;
				}else {
					var characters = '0123456789';
					var charactersLength = characters.length;

					for(var i = 0; i < 16; i++) {
						result += characters.charAt(Math.floor(Math.random() * charactersLength));
					}

					if(message === 'app_eui') {
						this.form.app_eui = result;
					}else if(message === 'dev_eui') {
						this.form.dev_eui = result;
					}
				}
			},

			changeDeviceType() {
				if(this.form.device_type !== 'level' && this.form.device_type !== null) {
					this.form.container_type = null
					this.form.container_characteristic = null
					this.form.location = null
					this.map = null
					this.marker = null
					this.lat = null
					this.lng = null
				}

				if(this.form.device_type === 'level') {
					this.initMap()
				}
			},

			changeTenantId() {
				if(this.isFullTenant === 1) {
					this.applicationsTenant.forEach((allTenant) => {
						allTenant.applications.forEach((application) => {
							if(this.form.tenant_id !== application.tenant_id) {
								this.form.app_id = null
							}
						})
					})
				}
			}
		}
	}
</script>

<style>
	.custom-select {
		padding: 0px 0px;
	}
</style>
