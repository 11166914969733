<template>
    <div class="col-md-3">
        <div class="custom-block">
            <h5>Capteurs</h5>
            <ul>
                <li v-for="device in application.devices" :key="device.id">
                    <a :href="`/tenants/${application.tenant_id}/devices/${device.id}`">
                        {{ device.device_name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        application: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>