<template>
    <div :class="hasDoorAndMotionDevices ? 'col-md-3' : 'd-none'">
        <div class="custom-block">
            <h5>État des capteurs</h5>
            <ul>
                <li v-for="device in doorAndMotionDevices" :key="device.id">
                    {{ device.device_name }} - 
                    <button
                    :class="['btn', 'rounded-circle', 'p-2', { 
                        'btn-success': device.device_on === 'true',
                        'btn-danger': device.device_on !== 'true'
                        }]"
                    :title="device.device_on === 'true' ? 'Activé' : 'Désactivé'"
                    disabled
                    ></button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        application: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasDoorAndMotionDevices () {
            return this.application.devices.some(device => device.device_type === 'door' || device.device_type === 'motion')
        },

        doorAndMotionDevices () {
            return this.application.devices.filter(device => device.device_type === 'door' || device.device_type === 'motion')
        },
    }
}
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>