<template>
    <div class="custom-block">
        <h4>Relais ({{ totalRows }})</h4>
        <b-form-group label="Nombre de lignes" label-for="nb-per-page" label-cols-lg="3">
            <b-form-select
                class="nb-per-page col-lg-3"
                v-model="perPage"
                :options="perPageOptions"
            >
            </b-form-select>
        </b-form-group>
        
        <b-table 
            hover 
            responsive 
            bordered 
            :per-page="perPage" 
            :currentPage="currentPage" 
            :fields="fields" 
            :items="alarmProvider" 
            :busy.sync="isBusy"
        >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Chargement...</strong>
                </div>
            </template>

            <template #cell(device_name) = "data">
                <b-link :href="`/tenants/${tenantId}/devices/${data.item.id}`">{{ data.item.device_name }}</b-link>
            </template>
            <template #cell(alarm_on) = "data">
                <b-form-checkbox v-if="data.item.device_type === 'relay'" switch v-model="data.item.alarm_on"
                    @input="switchAlarmOnOff(data.item.id, data.item.alarm_on, data.item.device_name)">
                    <span v-if="data.item.alarm_on == 'true' || data.item.alarm_on == true" style="color:green">Activée</span>
                    <span v-else style="color:red">Désactivée</span>
                </b-form-checkbox>
            </template>
            <template #cell(alarm_off) = "data">
                <div class="form-group row mb-0">
                    <div class="col-md-12">
                        <b-button 
                            type="submit" 
                            variant="primary" 
                            v-model="btn_alarm_off" 
                            @click="alarmOff(data.item.id)"
                        >
                            Arrêter l'alarme
                        </b-button>
                    </div>
                </div>
            </template>
        </b-table>

        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
    </div>
</template>

<script>
    export default {
        props: {
            application: {
                required: true,
                type: Object
            },

            tenantId: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                fields: [
                    { key: 'device_name', label: 'Alarme', visible: true },
                    { key: 'alarm_on', label: 'Alarme statut', visible: true },
                    { key: 'alarm_off', label: "Arrêter l'alarme", visible: true }
                ],
                isBusy: false,
                totalRows: 0,
                perPage: 5,
                perPageOptions: [5, 10, 25],
                currentPage: 1,
                btn_alarm_off: false,

            }
        },

        methods: {
            alarmProvider(ctx){
                const promise = axios.get(`/api/tenants/${this.tenantId}/applications/${this.application.id}/alarms`, {
                    params: {
                        per_page: ctx.perPage,
                        page: ctx.currentPage
                    }
                })

                return promise.then((response) => {
                    const items = response.data.data
                    this.totalRows = response.data.total

                    return items || []
                })
                .catch(() => {
                    return []
                })
            },

            switchAlarmOnOff(device_id, alarm_on, device_name){
                // this.alarm_activated = alarm_on ? 'true' : 'false'

                axios.post(`/tenants/${this.tenantId}/applications/${this.application.id}/devices/${device_id}/switch-alarm-on-off`, {
                    'alarm_on': alarm_on,
                })
                .then(() => {
                    alert(`L'alarme ${device_name} est maintenant ${alarm_on ? 'activée': 'désactivée'}`);
                })
                .catch((error) => {
                    // console.log(error);
                });
            },

            // A voir quand il sera en dev
            alarmOff(device_id){
                this.btn_alarm_off = true;

                axios.post(`/tenants/${this.tenantId}/applications/${this.application.id}/devices/${device_id}/alarm-off`, {
                    'btn_alarm_off': this.btn_alarm_off,
                    // 'device_id': device_id
                })
                .then(() => {
                    alert(`L'alarme a bien été éteinte`);
                })
                .catch((error) => {
                    // console.log(error);
                });

                this.btn_alarm_off = false;
            }
        }
    }
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>