<template>
    <div :class="noData ? 'd-none' : 'col-md-6'">
        <div class="custom-block">
            <h4>Dernières données</h4>
            <b-table
                :id="`last-datas-table-application-${applicationId}-device-${device.id}`"
                hover
                responsive
                bordered
                :fields="allFields()"
                :items="datasProvider"
                :busy.sync="isBusy"
            >

                <template #table-busy>
                    <div class="text-center text-danger my-">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Chargement...</strong>
                    </div>
                </template>

                <template #cell(device_name)>
                    {{device.device_name}}
                </template>

                <template #cell(battery) = "{ value }">
                    {{ value }}%
                </template>

                <template #cell(temperature) = "{ value }">
                    {{ value }}°C
                </template>

                <template #cell(humidity) = "{ value }">
                    {{ value }}%
                </template>

                <template #cell(core_temperature) = "{ value }">
                    {{ value }}°C
                </template>

                <template #cell(gyroscope_status_move) = "data">
                    <template v-if="data.item.gyroscope_status_move === 1">
                        {{ data.item.date }}
                    </template>
                    <template v-else-if="data.item.gyroscope_status_move === 0">
                        Pas de brassage
                    </template>
                </template>

                <template #cell(opening_status_open) = "{ value }">
                    <template v-if="value === 1">
                        Ouvert
                    </template>
                    <template v-else-if="value === 0">
                        Fermé
                    </template>
                </template>

                <template #cell(opening_status_time) = "{ value }">
                    {{ value }} min
                </template>

                <template #cell(room_status_occupied) = "{ value }">
                    <template v-if="value === 1">
                        Occupé
                    </template>
                    <template v-else-if="value === 0">
                        Vide
                    </template>
                </template>

                <template #cell(room_status_time) = "{ value }">
                    {{ value }} min
                </template>

                <template #cell(rate) = "{ value }">
                    {{ value }}%
                </template>
            
                <template #cell(location)>
                    {{ device.location }}
                </template>

                <!-- <template #cell(container_type)>
                    {{ device.container_type }}
                </template>

                <template #cell(container_characteristic)>
                    {{ device.container_characteristic }}
                </template> -->
            </b-table>

            <b-button @click="switchTabHandler()">Voir plus</b-button>

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            device: {
                required: true,
                type: Object
            },
            applicationId: {
                required: true,
                type: [String, Number]
            },
            tenant: {
                required: true,
                type: Object
            },
            currentTab: {
                required: true,
                type: Number
            }
        },

        data() {
            return {
                fields: [
                    { key: 'device_name', label: 'Capteur', visible: true },
                    { key: 'date', sortable: true, label: 'Date', visible: true, formatter: value => {
                        const date = new Date(value)
                        function addZero(i) {
                            if (i < 10) {i = "0" + i}
                            return i;
                        }
                        let formatted_date = addZero(date.getDate()) + "/" + addZero(date.getMonth() +1) + "/" + date.getFullYear() + " - " + addZero(date.getHours()) + ":" + addZero(date.getMinutes()) + ":" + addZero(date.getSeconds())

                        return formatted_date;
                        }
                    },
                    { key: 'battery', sortable: true, label: 'Batterie', visible: true },
                    { key: 'temperature', label: 'Température', visible: true },
                    { key: 'humidity', label: 'Humidité', visibleCompostmetre: true },
                    { key: 'core_temperature', label: 'Température au coeur', visibleCompostmetre: true },
                    { key: 'gyroscope_status_move', label: 'Dernier brassage', visibleCompostmetre: true },
                    { key: 'opening_status_open', label: 'État Porte', visibleDoor: true },
                    { key: 'opening_status_count', label: 'Compteur Porte', visibleDoor: true },
                    { key: 'opening_status_time', label: 'Temps Porte', visibleDoor: true },
                    { key: 'room_status_occupied', label: 'État mouvement', visibleMotion: true },
                    { key: 'room_status_count', label: 'Compteur Mouvement', visibleMotion: true },
                    { key: 'room_status_time', label: 'Temps Mouvement', visibleMotion: true },
                    { key: 'location', label: 'Localisation', visibleLevel: true },
                    { key: 'rate', sortable: true, label: 'Taux de remplissage', visibleLevel: true },
                    // { key: 'container_type', label: 'Contenant', visibleLevel: true },
                    // { key: 'container_characteristic', label: 'Contenu', visibleLevel: true },
                ],
                isBusy: false,
                formattedDate: '',
                noData: null,
                totalRows: 0,
                newTab: null,
                intervalId: null
            }
        },

        mounted() {
            const self = this
            this.intervalId = setInterval(function() {
                self.$root.$emit('bv::refresh::table', `last-datas-table-application-${self.applicationId}-device-${self.device.id}`)
            }, 10000)
        },

        beforeDestroy(){
            clearInterval(this.intervalId)
        },

        methods: {
            datasProvider(ctx) {
                const promise = axios.get(`/api/tenants/${this.tenant.id}/devices/${this.device.id}/datas`, {
                    params: {
                        per_page: ctx.perPage,
                        page: ctx.currentPage,
                        sort_by: ctx.sortBy,
                        sort_desc: ctx.sortDesc
                    }
                })

                return promise.then((response) => {
                    const items = response.data.data.slice(0, 6)
                    this.totalRows = response.data.total
                    
                    if(response.data.total === 0) {
                        this.noData = "Vous n'avez pas de données"
                    }

                    return items || []
                })
                .catch(() => {
                    return []
                })
            },

            allFields() {
                let visibleFields = []

                if(this.device.device_type === 'level') {
                    this.fields.forEach((field) => {
                        if((field.visible || field.visibleLevel) && field.key !== 'temperature') {
                            visibleFields.push({...field})
                        }
                    });
                }else if(this.device.device_type === 'door') {
                    this.fields.forEach((field) => {
                        if(field.visible || field.visibleDoor) {
                            visibleFields.push({...field})
                        }
                    });
                }else if(this.device.device_type === 'motion') {
                    this.fields.forEach((field) => {
                        if(field.visible || field.visibleMotion) {
                            visibleFields.push({...field})
                        }
                    });
                }else if(this.device.device_type === 'compostmetre') {
                    this.fields.forEach((field) => {
                        if(field.visible || field.visibleCompostmetre || field.key === 'location') {
                            visibleFields.push({...field})
                        }
                    });
                }

                return visibleFields
            },

            switchTabHandler() {
                this.newTab = this.currentTab + 1;
                this.$emit('send-tab', this.newTab);
            },
        }
    }
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>