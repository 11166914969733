<template>
    <div :class="hasCompostmetreDevices ? 'col-md-6' : 'd-none'">
        <div class="custom-block">

            <h5>Compost'mètre</h5>

            <template v-if="loading">
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Chargement...</strong>
                </div>
            </template>
            <template v-else>
    
                <template v-for="device in datasCompost.devices">
                    <div class="custom-block" v-if="device.last_data !== null" :key="device.id">
                        <b-form-group label="Nom du capteur" label-for="device_name" class="mb-3">
                            <b-form-input 
                                id="device_name" 
                                class="mb-3 cursor-not-allowed" 
                                disabled 
                                readonly 
                                v-model="device.device_name"
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group label="Localisation du capteur" label-for="location" class="mb-3">
                            <b-form-input 
                                id="location" 
                                class="mb-3 cursor-not-allowed" 
                                disabled 
                                readonly 
                                v-model="device.location"
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group label="Batterie" label-for="battery" class="mb-3">
                            <b-form-input 
                                id="battery" 
                                class="mb-3 cursor-not-allowed" 
                                disabled 
                                readonly 
                                v-model="device.last_data.battery + '%'"
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group label="Taux d'humidité" label-for="humidity" class="mb-3">
                            <b-form-input 
                                id="humidity" 
                                class="mb-3 cursor-not-allowed" 
                                disabled 
                                readonly 
                                v-model="device.last_data.humidity + '%'"
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group label="Température" label-for="temperature" class="mb-3">
                            <b-form-input 
                                id="temperature" 
                                class="mb-3 cursor-not-allowed" 
                                disabled 
                                readonly 
                                v-model="device.last_data.temperature + '°C'"
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group label="Température au coeur" label-for="coreTemperature" class="mb-3">
                            <b-form-input 
                                id="coreTemperature" 
                                class="mb-3 cursor-not-allowed" 
                                disabled 
                                readonly 
                                v-model="device.last_data.core_temperature + '°C'"
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group label="Dernier brassage" label-for="gyroscope_move" class="mb-3">
                            <b-form-input 
                                id="gyroscope_move" 
                                class="mb-3 cursor-not-allowed" 
                                disabled 
                                readonly 
                                v-model="device.last_data.date"
                            >
                            </b-form-input>
                        </b-form-group>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        application: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            datasCompost: [],
            loading: true
        }
    },

    mounted() {
            setInterval(() => {
                this.insertDatas()
            }, 10000);
        },

    computed: {
        hasCompostmetreDevices() {
            return this.application.devices.some(device => device.device_type === 'compostmetre')
        },
    },

    methods: {
        insertDatas() {
            axios.get("/sanctum/csrf-cookie")
                .then(response => {
                    axios.get(`/api/tenants/${this.application.tenant_id}/applications/${this.application.id}/datas/compost`)
                    .then(response => {
                        this.datasCompost = response.data
                    })
                })
                .catch(error => {
                    console.log(error);
            })

            this.loading = false
        },
    }
}

</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>