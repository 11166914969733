<template>
    <button id="scrollToTopBtn" @click="scrollToTop" v-show="showButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
        </svg>
    </button>
</template>
  
<script>
    export default {
        data() {
            return {
                showButton: false
            }
        },

        methods: {
            scrollHandler() {
                const scrollToTopBtn = document.getElementById("scrollToTopBtn")
                if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                    this.showButton = true
                } else {
                    this.showButton = false
                }
            },

            scrollToTop() {
                const scrollStep = -window.scrollY / (500 / 15)
                const scrollInterval = setInterval(() => {
                    if (window.scrollY !== 0) {
                        window.scrollBy(0, scrollStep)
                    } else {
                        clearInterval(scrollInterval)
                    }
                }, 15)
            }
        },

        mounted() {
            window.addEventListener("scroll", this.scrollHandler)
        },

        beforeDestroy() {
            window.removeEventListener("scroll", this.scrollHandler)
        }
    };
</script>

<style scoped>
    #scrollToTopBtn {
        position: fixed;
        bottom: 20px;
        right: 20px;
        padding: 10px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        z-index: 999;
    }
</style>
  