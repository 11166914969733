<template>
   <section class="updateUser">
        <div class="row">
            <div class="col-12 mb-3">
                <h3 class="text-muted">Créer une application</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-form @submit="onSubmit">
                            <validationObserver>
                                <div class=row>
                                    <div class="col-12">
                                        <span style="float: right;">* champs obligatoires</span>                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <validationProvider name="Nom de l'application" rules="required|max:36|alphaNum" v-slot="{ errors }" class="mb-3 col-md-6">
                                        <b-form-group label-for="application_name">
                                            <span class="d-flex align-items-center">
                                                <label for="application_name" class="mr-2">Nom de l'application *</label>
                                                <span
                                                    v-b-popover.hover="'Entrez votre nom complet'"
                                                    tabindex="0"
                                                    role="button"
                                                    class="mb-2"
                                                    style="cursor: help;"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                                    </svg>
                                                </span>
                                            </span>

                                            <b-form-input id="application_name" v-model="form.application_name" type="text" required></b-form-input>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.application_name">{{ form_errors.application_name[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>

                                    <validationProvider name="Description" rules="max:2000" v-slot="{ errors }" class="mb-3 col-md-6">
                                      <b-form-group label="Description" label-for="application_description">
                                        <b-form-input id="application_description" v-model="form.application_description" type="text"></b-form-input>
                                        <span style="color:red">{{ errors[0] }}</span>
                                        <span style="color:red" v-if="form_errors.application_description">{{ form_errors.application_description[0] }}</span>
                                      </b-form-group>
                                    </validationProvider>
                                </div>
                                <div class="row">
                                    <validationProvider name="API Key" rules="required|length:92|capsNum" v-slot="{ errors }" class="mb-3 col-md-12">
                                        <b-form-group label="API Key *" label-for="apiKey">
                                            <b-form-input id="apiKey" v-model="form.apiKey" type="text" required></b-form-input>
                                            <b-button variant="primary" @click="generator($event)">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                                <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                              </svg>
                                              <span class="align-bottom">Générer</span>
                                            </b-button>
                                            <span style="color:red">{{ errors[0] }}</span>
                                            <span style="color:red" v-if="form_errors.apiKey">{{ form_errors.apiKey[0] }}</span>
                                        </b-form-group>
                                    </validationProvider>
                                </div>
                                
                                <div class="row">
                                    <div class="mb-3 col-md-6">
                                        <b-form-group label="Est-ce que vous allez avoir des capteurs de niveau ? *" v-slot="{ ariaDescribedBy }">
                                            <b-form-radio v-model="form.deviceLevel" @change="initMap()" 
                                                :aria-describedby="ariaDescribedBy" name="yes" value="yes">
                                                    Oui
                                            </b-form-radio>
                                            <b-form-radio v-model="form.deviceLevel" @change="resetMarker()" 
                                                :aria-describedby="ariaDescribedBy" name="no" value="no">
                                                    Non
                                            </b-form-radio>
                                        </b-form-group>
                                    </div>

                                    <div v-if="form.deviceLevel == 'yes'" class="mb-3 col-md-6">
                                        <p>Veuillez cliquer sur la carte pour choisir le point central de votre carte</p>
                                        <span>Point de tri *</span>
                                        <!-- A mettre dans le ? (aide) -->
                                        <!-- Ce point sera utilisé pour centrer la carte et pour gérer le point de départ et d/'arrivée -->
                                        <div id="map" style="width: 100%; height: 200px;"></div>
                                        <span v-if="lat && lng != ''">lat: {{ lat }} / lng: {{ lng }}</span>
                                        <span v-else style="color:red">La latitude et la longitude ne sont pas encore ajoutées</span><br>
                                        <span style="color:red" v-if="form_errors.lat">{{ form_errors.lat[0] }}</span><br>
                                        <span style="color:red" v-if="form_errors.lng">{{ form_errors.lng[0] }}</span>
                                    </div>
                                </div>

                                <div v-if="isFullTenant === 1" class="row">
                                    <div class="mb-3 col-md-6">
                                        <validationProvider name="Nom du groupe" rules="required" v-slot="{ errors }" class="mb-3 col-md-6">
                                            <b-form-group label="Nom du groupe *" label-for="tenant_id">
                                                <b-form-select id="tenant_id" v-model="form.tenant_id" required>
                                                    <b-form-select-option :value="null" disabled>Veuillez sélectionner un groupe</b-form-select-option>
                                                    <template v-for="tenant in allTenant">
                                                        <b-form-select-option :key="tenant.id" v-model="tenant.id">{{ tenant.tenant_name }}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <span style="color:red">{{ errors[0] }}</span>
                                            </b-form-group>
                                        </validationProvider>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-10">
                                        <b-button type="submit" variant="primary">Créer</b-button>
                                    </div>
                                </div>
                            </validationObserver>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
	import L from 'leaflet';
	import 'leaflet/dist/leaflet.css';

    import 'leaflet.fullscreen/Control.FullScreen.js';
	import 'leaflet.fullscreen/Control.FullScreen.css';

    export default {
        props: {
            isFullTenant: Number,
            tenant: { 
                type: Object,
                required: true
            },
            allTenant: Array
        },

        data() {
            return {
                form: {
                    application_name: null,
                    application_description: null,
                    apiKey: null,
                    deviceLevel: null,
                    tenant_id: null
                },

                form_errors: {},

                icon: L.icon({
					iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
					shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowSize: [41, 41]
				}),

                map: null,
                marker: null,
                zoom: 7,
                center: [48.8566, 2.3522],
                url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVsaWVuZm9pc29uIiwiYSI6ImNrNWZlYmg0bDA3OW0za282azZ1amNpMnMifQ.CPy2dQrh_3rd35jxxT5sTQ',
                attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                lat: null,
                lng: null 
            }
        },

        methods: {
            initMap() {
                this.map = L.map('map', {
                    zoom: this.zoom,
                    center: this.center,

                    fullscreenControl: true,
						fullscreenControlOptions: {
							position: 'topleft'
					}
                });

                L.tileLayer(this.url, {
					attribution: this.attribution
				}).addTo(this.map);

                this.map.on('click', this.onMapClick);
            },

            onMapClick(event) {
                const latLng = event.latlng;
                this.lat = latLng.lat;
                this.lng = latLng.lng;

                if(this.marker) {
                    this.map.removeLayer(this.marker)
                }

                this.marker = L.marker(latLng, { icon: this.icon }).addTo(this.map);

                if(this.markerError != null) {
                    this.markerError = null
                }
            },

            resetMarker() {
                this.marker = null
                this.lat = null
                this.lng = null
            },

            onSubmit(event) {
                event.preventDefault();

                if(this.isFullTenant === 1){
                    axios.post(`/tenants/${this.form.tenant_id}/applications`, {
                        'application_name': this.form.application_name,
                        'application_description': this.form.application_description,
                        'apiKey': this.form.apiKey,
                        'tenant_id': this.form.tenant_id,
                        'deviceLevel': this.form.deviceLevel,
                        'lat': this.lat,
                        'lng': this.lng
                    })
                    .then(response => {
                        alert(`L'application ${this.form.application_name} a bien été créée !`);
                        window.location.href = `/tenants/${this.tenant.id}/dashboard`;
                    })
                    .catch(error => {
                        this.form_errors = error.response.data.errors
                    })
                }else{
                    axios.post(`/tenants/${this.tenant.id}/applications`, {
                        'application_name': this.form.application_name,
                        'application_description': this.form.application_description,
                        'apiKey': this.form.apiKey,
                        'lat': this.lat,
                        'lng': this.lng
                    })
                    .then(response => {
                        alert(`L'application ${this.form.application_name} a bien été créée !`);
                        window.location.href = `/tenants/${this.tenant.id}/dashboard`;
                    })
                    .catch(error => {
                        this.form_errors = error.response.data.errors
                    })
                }
            },

            generator(event) {
                event.preventDefault();
                var result = '';
                var characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                var charactersLength = characters.length;
                for ( var i = 0; i < 39; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                result += '.';
                for ( var i = 0; i < 52; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                this.form.apiKey = result;
            }
        }
    }
</script>

<style>
</style>
