<template>
    <div v-if="device.device_type === 'level'" class="col-md-6">
        <div class="custom-block">
            <h4>Graphique</h4>
            <devices-line-chart-level :application-id="applicationId" :tenant="tenant" :device="device" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            device: {
                required: true,
                type: Object
            },
            applicationId: {
                required: true,
                type: [String, Number]
            },
            tenant: {
                required: true,
                type: Object
            }
        },

        data() {
            return {

            }
        }
    }
</script>

<style>
    .custom-block {
        border-radius: 20px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
    }
</style>