<template>
	<div>
		<ul class="nav nav-tabs" id="mytab" role="tablist">
			<li v-for="application, index in tenant.applications" class="nav-item">
				<template v-for="device_type in application.device_types">
					<template v-if="device_type.type === 'Niveau'">
						<!-- A revoir ! Quand il y a plusieurs app et que l'index 0 n'a pas de capteur de niveau -->
						<a v-if="index === 0" class="nav-link active" @click="resetSizeMap(`map-application-${application.id}`)" :id="`application-${application.id}-tab`"
						data-toggle="tab" :href="`#map-application-${application.id}`" role="tab" :aria-controls="`map-application-${application.id}`" aria-selected="true">
							Carte de l'application {{ application.application_name }}
						</a>
						<a v-else class="nav-link" @click="resetSizeMap(`map-application-${application.id}`)" :id="`application-${application.id}-tab`" data-toggle="tab"
						:href="`#map-application-${application.id}`" role="tab" :aria-controls="`map-application-${application.id}`">
							Carte de l'application {{ application.application_name }}
						</a>
					</template>
				</template>
			</li>
    	</ul>
    	<div class="tab-content" id="myTabContent">
			<template v-for="application, index in tenant.applications">
				<template v-for="device_type in application.device_types">
					<template v-if="device_type.type === 'Niveau'">
						<div v-if="index === 0" class="tab-pane fade show active" :id="`map-application-${application.id}`" role="tabpanel" :aria-labelledby="`application-${application.id}-tab`">
							<div :id="`map-application-${application.id}`" style="width: 100%; min-height: 800px; padding: 0; margin: 0; position: relative; z-index: 0;"></div>
						</div>
						<div v-else class="tab-pane fade show" :id="`map-application-${application.id}`" role="tabpanel" :aria-labelledby="`application-${application.id}-tab`">
							<div :id="`map-application-${application.id}`" style="width: 100%; min-height: 800px; padding: 0; margin: 0; position: relative; z-index: 0;"></div>
						</div>
					</template>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
	import L from 'leaflet';
	import 'leaflet/dist/leaflet.css';

	import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
	import 'leaflet-control-geocoder/dist/Control.Geocoder';

	import 'leaflet-routing-machine/dist/leaflet-routing-machine';
	import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

	import 'leaflet.markercluster/dist/MarkerCluster.css';
	import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

	import 'leaflet.markercluster/dist/leaflet.markercluster.js';

	import 'leaflet.fullscreen/Control.FullScreen.js';
	import 'leaflet.fullscreen/Control.FullScreen.css';

	import { GestureHandling } from "leaflet-gesture-handling";
	import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";


	export default {
		name: 'devices-map',
		props: {
			tenant: Object
		},
		
		data() {
			return {
				mapInfo: [],
				maps: {},
				route: [],
				routeControl: {},

				greenIcon: L.icon({
					iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
					shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowSize: [41, 41]
				}),

				orangeIcon: L.icon({
					iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
					shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowSize: [41, 41]
				}),

				redIcon: L.icon({
					iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
					shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowSize: [41, 41]
				}),

				blueIcon: L.icon({
					iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
					shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
					iconSize: [25, 41],
					iconAnchor: [12, 41],
					popupAnchor: [1, -34],
					shadowSize: [41, 41]
				}),

				lastDatas: [],
				layersControlRate: {},
				markers: {},
			};
		},

		async mounted() {
			this.initMap()

			try {
				await this.insertDatas()
				this.addRateLayer()
				this.addBtn()
				this.addRouting()
			} catch(error) {
				console.error(error)
			}
		},

		methods: {
			initMap(){
				L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

				this.tenant.applications.forEach(application => {
					application.device_types.forEach(device_type => {
						if(device_type.type === 'Niveau'){
							this.mapInfo.push({
								id: `map-application-${application.id}`,
								center: [application.lat, application.lng],
								zoom: 15
							})
						}
					})
				})

				this.mapInfo.forEach(({
					id,
					center,
					zoom
				}) => {
					this.maps[id] = L.map(id, {
						center: center,
						zoom: zoom,

						// Plugin Ctrl+Wheel zoom
						gestureHandling: true,
						
						// Plugin FullScreen
						fullscreenControl: true,
						fullscreenControlOptions: {
							position: 'topleft'
						}
					});

					L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVsaWVuZm9pc29uIiwiYSI6ImNrNWZlYmg0bDA3OW0za282azZ1amNpMnMifQ.CPy2dQrh_3rd35jxxT5sTQ', {
						attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
					}).addTo(this.maps[id]);
				})
			},

			resetSizeMap(id){
				this.maps[id].invalidateSize(true)
			},

			async insertDatas(callback){
				try {
					setInterval(() => {
						// this.tenant.applications.forEach(application => {
							axios.get('/sanctum/csrf-cookie')
								.then(response => {
									axios.get(`/api/tenants/${this.tenant.id}/datas/levels`)
										.then(response => {
											this.lastDatas = response.data
											if(callback) {
												callback()
											}
										})
										.catch(error => {
											console.log(error);
										})
								})
								.catch(error => {
									console.log(error);
								})
						// })
					}, 10000)
				} catch(error) {
					throw error
				}
			},

			async addRouting(){
				await new Promise((resolve) => this.insertDatas(resolve))

				this.mapInfo.forEach(({id}) => {
					this.routeControl[id] = L.Routing.control({
						lineOptions: {
							styles: [{color: '#33b1ff', opacity: 1, weight: 7 }]
						},
						waypoints: [
							L.latLng(48.842169, -1.567722),
							L.latLng(48.842169, -1.567722)
						],
						router: new L.Routing.osrmv1({
							language: 'fr',
							profile: 'car',
						}),
						geocoder: L.Control.Geocoder.nominatim(),
						routeWhileDragging: false,
						draggableWaypoints: false,
						addWaypoints: false,
						fitSelectedRoutes: false,

						createMarker(i, wps) {
							return L.marker(wps.latLng, {icon: L.icon({
								iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
								shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png',
								iconSize: [25, 41],
								iconAnchor: [12, 41],
								popupAnchor: [1, -34],
								shadowSize: [41, 41]
							}), draggable: false})
						},
					}).addTo(this.maps[id]);
				})
			},

			addWaypoints(btnName){
				this.mapInfo.forEach(({id}) => {
					if(btnName.data.id === `createRoute-${id}`){
						this.route.push(L.latLng(this.routeControl[id].getWaypoints()[0].latLng.lat, this.routeControl[id].getWaypoints()[0].latLng.lng)),
						this.lastDatas.applications.forEach(applications => {
							if(id === `map-application-${applications.id}`){
								applications.devices.forEach(devices => {
									// devices.last_data.forEach(lastData => {
										if(devices.last_data.rate >= 78){
											this.route.push(L.latLng(devices.lat, devices.lon));
										}
									// });
								});
							}
						});
						this.route.push(L.latLng(this.routeControl[id].getWaypoints()[this.routeControl[id].getWaypoints().length -1].latLng.lat, this.routeControl[id].getWaypoints()[this.routeControl[id].getWaypoints().length -1].latLng.lng))
						this.routeControl[id].setWaypoints(this.route);
						this.route = [];
					}
				})
			},

			resetWaypoints(btnName){
				const i = 0

				this.mapInfo.forEach(({id}) => {
					console.log(this.tenant.applications[0].lat)
					if(btnName.data.id === `resetRoute-${id}`){
						if(this.routeControl[id].getWaypoints().length > 2){
							this.routeControl[id].setWaypoints([
								L.latLng(this.tenant.applications.i.lat, this.tenant.applications.i.lng),
								L.latLng(this.tenant.applications.i.lat, this.tenant.applications.i.lng)
							])
						}
					}
					i +=1 
				})
			},

			async addRateLayer(){
				try {
					await new Promise((resolve) => this.insertDatas(resolve))

					setInterval(() => {
						this.mapInfo.forEach(({id}) => {
							var all = L.layerGroup().addTo(this.maps[id]),
								empty = L.layerGroup(),
								half_full = L.layerGroup(),
								full = L.layerGroup();

							var marker = {};

							if(this.layersControlRate[id] != undefined){
								this.layersControlRate[id].remove(this.maps[id]);
							}

							this.lastDatas.applications.forEach(applications => {
								applications.devices.forEach(devices => {
									if(this.markers[id] != undefined){
										this.markers[id][devices.id].remove(this.maps[id]);
									}

									if(devices.last_data.rate >= 78 && devices.last_data.rate <= 100){
										marker[devices.id] = L.marker([devices.lat, devices.lon], {icon: this.redIcon})
											.bindPopup(`<b><a href="/tenants/${this.tenant.id}/devices/${devices.id}/datas">${devices.device_name}</a></b> <br> Localisation: ${devices.location} <br> Taux de remplissage: ${devices.last_data.rate}%
											<br> Batterie: ${devices.last_data.battery}%`)
											.addTo(all).addTo(full)
									}else if(devices.last_data.rate > 45 && devices.last_data.rate < 78){
										marker[devices.id] = L.marker([devices.lat, devices.lon], {icon: this.orangeIcon})
											.bindPopup(`<b><a href="/tenants/${this.tenant.id}/devices/${devices.id}/datas">${devices.device_name}</a></b><br> Localisation: ${devices.location} <br> Taux de remplissage: ${devices.last_data.rate}%
											<br> Batterie: ${devices.last_data.battery}%`)
											.addTo(all).addTo(half_full)
									}else if(devices.last_data.rate >= 0 && devices.last_data.rate <= 45){
										marker[devices.id] = L.marker([devices.lat, devices.lon], {icon: this.greenIcon})
											.bindPopup(`<b><a href="/tenants/${this.tenant.id}/devices/${devices.id}/datas">${devices.device_name}</a></b><br> Localisation: ${devices.location} <br> Taux de remplissage: ${devices.last_data.rate}%
											<br> Batterie: ${devices.last_data.battery}%`)
											.addTo(all).addTo(empty)
									}else{
										marker[devices.id] = L.marker([devices.lat, devices.lon], {icon: this.blueIcon})
											.bindPopup(`<b><a href="/tenants/${this.tenant.id}/devices/${devices.id}/datas">${devices.device_name}</a></b><br> Localisation: ${devices.location} <br> Taux de remplissage: ${devices.last_data.rate}%
											<br> Batterie: ${devices.last_data.battery}%`)
											.addTo(all)
									}
								})
							})

							this.markers[id] = marker;

							var rateLayers = {
								'Tout': all,
								'Vide': empty,
								'Mi-plein': half_full,
								'Plein': full,
							};

							this.layersControlRate[id] = L.control.layers(rateLayers, null, {position: 'topleft'}).addTo(this.maps[id]);
						})
					}, 10000)
				} catch(error) {
					console.error(error)
				}
			},

			async addBtn(){
				await new Promise((resolve) => this.insertDatas(resolve))

				this.mapInfo.forEach(({id}) => {
					L.Control.AddBtnCreateRoute = L.Control.extend({
						options: { position: 'topright' },
						onAdd: function(map){
							var btnCreateRoute = L.DomUtil.create('input')
							btnCreateRoute.type = 'button'
							btnCreateRoute.id = `createRoute-${id}`
							btnCreateRoute.title = "Création de l'itinéraire"
							btnCreateRoute.value = 'Créer un itinéraire'
							btnCreateRoute.style.height = '30px'
							return btnCreateRoute;
						}
					});

					L.Control.AddBtnResetRoute = L.Control.extend({
						options: { position: 'topright' },
						onAdd: function(map){
							var btnResetRoute = L.DomUtil.create('input')
							btnResetRoute.type = 'button'
							btnResetRoute.id = `resetRoute-${id}`
							btnResetRoute.title = "Réinitialisation de l'itinéraire"
							btnResetRoute.value = "Réinitialiser l'itinéraire"
							btnResetRoute.style.height = '30px';
							return btnResetRoute;
						}
					});

					L.control.addBtnCreateRoute = new L.Control.AddBtnCreateRoute();
					L.control.addBtnCreateRoute.addTo(this.maps[id]);

					L.control.addBtnResetRoute = new L.Control.AddBtnResetRoute();
					L.control.addBtnResetRoute.addTo(this.maps[id]);

					$(`#createRoute-${id}`).on('click', {
						id: `createRoute-${id}`
					}, this.addWaypoints);
					$(`#resetRoute-${id}`).on('click', {
						id: `resetRoute-${id}`
					}, this.resetWaypoints);
				})
			}
		}
	}
</script>

<style>
</style>
